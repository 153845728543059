// A definition of all API endpoints
const api = {
  open_tickets: { method: "GET", url: "/api/tickets/open" },
  incidents_month: { method: "GET", url: "/api/tickets/incidents/current_month" },
  incidents_year: { method: "GET", url: "/api/tickets/incidents/current_year" },
  incidents_use_cases: { method: "GET", url: "/api/tickets/use_cases" },
  incidents_use_case_scope_month: { method: "GET", url: "/api/tickets/scope/current_month" },
  incidents_use_case_business_risk_month: { method: "GET", url: "/api/tickets/business_risk/current_month" },
  intel: { method: "GET", url: "/api/tickets/intel/current_month" }
};

export const fetchBegin = name => ({
  type: "FETCH_BEGIN",
  name: name
});

export const fetchSuccess = (name, json) => ({
  type: "FETCH_SUCCESS",
  name: name,
  json: json
});

export const fetchError = (name, error) => ({
  type: "FETCH_ERROR",
  name: name,
  error: error
});

export const fetchData = (name, data) => {
  return dispatch => {
    // dispatch start
    dispatch(fetchBegin(name));

    let options = {
      method: api[name].method
    };

    if (data !== undefined) {
      options.body = JSON.stringify(data);
      options.headers = {
        "Content-Type": "application/json"
      };
    }

    // fetch
    // then-success -> dispatch result
    // then-error -> dispatch error
    return fetch(api[name].url, options)
      .then(
        response => {
          console.log(response);
          return response.json();
        },
        error => dispatch(fetchError(name, error))
      )
      .then(json => {
        dispatch(fetchSuccess(name, json));
      }, (error) => dispatch(fetchError(name, error)));
  };
};
