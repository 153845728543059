const API_AUTH_STATUS = '/api/auth/status'
const API_AUTH_LOGIN = '/api/auth/login'
const API_AUTH_LOGOUT = '/api/auth/logout'
const API_AUTH_MFA = '/api/auth/mfa'
const API_AUTH_GET_ACTIVATION = '/api/auth/activate'
const API_AUTH_ACTIVATE = '/api/auth/activate'

// Actions which will result in loading state changes
const statusLoading = () => ({
  type: "AUTH_STATUS_LOADING"
})

const loginLoading = () => ({
  type: "AUTH_LOGIN_LOADING"
})

const mfaLoading = () => ({
  type: "AUTH_MFA_LOADING"
})

const logoutLoading = () => ({
  type: "AUTH_LOGOUT_LOADING"
})

const getActivationLoading = () => ({
  type: "AUTH_GET_ACTIVATION_LOADING"
})

const activateLoading = () => ({
  type: "AUTH_ACTIVATE_LOADING"
})

// Actions which save the response in the state
const statusResponse = (response) => ({
  type: "AUTH_STATUS_RESPONSE",
  response: response
})

const loginResponse = (response) => ({
  type: "AUTH_LOGIN_RESPONSE",
  response: response
})

const logoutResponse = (response) => ({
  type: "AUTH_LOGOUT_RESPONSE",
  response: response
})

const mfaResponse = (response) => ({
  type: "AUTH_MFA_RESPONSE",
  response: response
})

const getActivationResponse = (response) => ({
  type: "AUTH_GET_ACTIVATION_RESPONSE",
  response: response
})

const activateResponse = (response) => ({
  type: "AUTH_ACTIVATE_RESPONSE",
  response: response
})

// Actions which save the decoded json in the state
const statusJson = (json) => ({
  type: "AUTH_STATUS_JSON",
  json: json
})

const loginJson = (json, credentials) => ({
  type: "AUTH_LOGIN_JSON",
  json: json,
  credentials: credentials
})

const logoutJson = (json) => ({
  type: "AUTH_LOGOUT_JSON",
  json: json
})

const mfaJson = (json) => ({
  type: "AUTH_MFA_JSON",
  json: json
})

const getActivationJson = (json) => ({
  type: "AUTH_GET_ACTIVATION_JSON",
  json: json
})

const activateJson = (json) => ({
  type: "AUTH_ACTIVATE_JSON",
  json: json
})

// Actions which will save either network errors or
// user/logic errors in the state.
const statusError = (error) => ({
  type: "AUTH_STATUS_ERROR",
  error: error
})

const loginError = (error) => ({
  type: "AUTH_LOGIN_ERROR",
  error: error
})

const logoutError = (error) => ({
  type: "AUTH_LOGOUT_ERROR",
  error: error
})

const mfaError = (error) => ({
  type: "AUTH_MFA_ERROR",
  error: error
})

const getActivationError = (error) => ({
  type: "AUTH_GET_ACTIVATION_ERROR",
  error: error
})

const activateError = (error) => ({
  type: "AUTH_ACTIVATE_ERROR",
  error: error
})

// Below are the actual actions which will be dispatched from the 
// App. These in turn will do a request and follow up with all the 
// actions above. getStatus is in charge of the actual login state,
// and is also called as a final action from both postLogout and
// postMfa. This means postLogout and postMfa do not have to handle 
// the actual loggedIn state.
export const getStatus = () => (
  (dispatch) => {
    dispatch(statusLoading())

    fetch(API_AUTH_STATUS).then(
      response => {
        dispatch(statusResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(statusJson(json)),
    ).catch(
      error => dispatch(statusError(error))
    )
  }
)

export const postLogin = (credentials) => (
  (dispatch) => {
    dispatch(loginLoading())

    let options = {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json"
      }
    }
    fetch(API_AUTH_LOGIN, options).then(
      response => {
        dispatch(loginResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(loginJson(json, credentials)),
    ).catch(
      error => dispatch(loginError(error))
    )
  }
)

export const postLogout = () => (
  (dispatch) => {
    dispatch(logoutLoading())

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    }
    fetch(API_AUTH_LOGOUT, options).then(
      response => {
        dispatch(logoutResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(logoutJson(json)),
    ).catch(
      error => dispatch(logoutError(error))
    ).then(
      // We queue the getStatus action, so only this 
      // action is in charge of login state
      () => dispatch(getStatus())
    )
  }
)

export const postMfa = (credentials) => (
  (dispatch) => {
    dispatch(mfaLoading())

    let options = {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json"
      }
    }
    fetch(API_AUTH_MFA, options).then(
      response => {
        dispatch(mfaResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(mfaJson(json)),
    ).catch(
      error => dispatch(mfaError(error))
    ).then(
      // We queue the getStatus action, so only this 
      // action is in charge of login state
      () => dispatch(getStatus())
    )
  }
)

export const getActivation = (key) => (
  (dispatch) => {
    dispatch(getActivationLoading())
    let options = {
      headers: {
        "Content-Type": "application/json"
      }
    }
    fetch(API_AUTH_GET_ACTIVATION + '/' + encodeURI(key), options).then(
      response => {
        dispatch(getActivationResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(getActivationJson(json))
    ).catch(
      error => dispatch(getActivationError(error))
    )
  }
)

export const postActivation = (key, password, code) => (
  (dispatch) => {
    dispatch(activateLoading())
    let data = {
      activation_key: key,
      password: password,
      verification_code: code
    }
    let options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }

    fetch(API_AUTH_ACTIVATE, options).then(
      response => {
        dispatch(activateResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(activateJson(json))
    ).catch(
      error => dispatch(activateError(error))
    )
  }
)
