import { connect } from "react-redux";
import ChangePassword from "../pwchangepage"
import {postChange, confirm} from '../../actions/pwchange'

const generateMessage = (state) => {
    let error = state.pwchange.error
    if (error !== undefined) {
        // The json failed to decode
        if (error === "Unexpected token P in JSON at position 0") {
            return "Onvoorzien probleem in de server"
        }
        // Return the raw error
        return error
    }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: generateMessage(state),
    pwchangeForm: state.form.pwchange,
    changed: state.pwchange.changed,
    confirmed: state.pwchange.confirmed
  };
};

const mapDispatchToProps = dispatch => {
    return {
        tryChange: (current, changed) => {
            dispatch(postChange({
                "current": current,
                "new": changed
            }))
        },
        confirmChanged: () => {
            dispatch(confirm())
        }
    }
}

export { generateMessage, mapStateToProps, mapDispatchToProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
