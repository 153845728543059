import activation from '../activationpage'
import { connect } from 'react-redux'
import { getActivation, postActivation } from '../../actions/login'

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activationForm: state.form.activation,
    activation: state.login.activation,
    loggedIn: state.login.loggedIn
  };
};

const mapDispatchToProps = dispatch => {
    return {
        tryActivate(key, password, mfa_key) {
            dispatch(postActivation(key, password, mfa_key))
        },
        get_activation(key) {
            dispatch(getActivation(key))
        }
    }
}

export { mapStateToProps, mapDispatchToProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(activation);
