import { connect } from 'react-redux'
import SecurityDashboard from './securitydashboard'
import { fetchData } from '../actions/api'

const mapStateToProps = (state /*, ownProps*/) => {
    return {
      open_tickets: state.api.open_tickets.result,
      incident_month_data: state.api.incidents_month.result,
      incident_year_data: state.api.incidents_year.result,
      incident_use_cases: state.api.incidents_use_cases.result,
      incidents_use_case_scope_month: state.api.incidents_use_case_scope_month.result,
      incidents_use_case_business_risk_month: state.api.incidents_use_case_business_risk_month.result,
      intel: state.api.intel.result,
    };
  };
  
  const mapDispatchToProps = dispatch => {
      return {
          fetch_open_tickets: () => {
            dispatch(fetchData("open_tickets"))
          },
          fetch_incidents_month: () => {
            dispatch(fetchData("incidents_month"))
          },
          fetch_incidents_year: () => {
            dispatch(fetchData("incidents_year"))
          },
          fetch_incident_use_cases: () => {
            dispatch(fetchData("incidents_use_cases"))
          },
          fetch_intel: () => {
            dispatch(fetchData("intel"))
          },
          fetch_incidents_use_case_scope_month: () => {
            dispatch(fetchData("incidents_use_case_scope_month"))
          },
          fetch_incidents_use_case_business_risk_month: () => {
            dispatch(fetchData("incidents_use_case_business_risk_month"))
          }
      }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SecurityDashboard);
