import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
    // Dit laat hem aansluiten
    marginTop: -16
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10
  },
  secButton: {
    position: 'absolute',
    zIndex: 1,
    right: 120,
    marginLeft: 18,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    '&:hover': { background: theme.palette.secondary.main }
  },
  serButton: {
    position: 'absolute',
    zIndex: 1,
    right: 16,
    marginLeft: 18,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    '&:hover': { background: theme.palette.secondary.main }
  },
  active: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': { background: theme.palette.secondary.dark }
  },
  subBar: {
    backgroundColor: theme.palette.primary.light
  }
})

function SubBar() {
  // We return an empty template to hide the sub-bar, the routes
  // are retained for the moment.
  return ""
}

SubBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SubBar)
