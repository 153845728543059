import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { NavLink } from 'react-router-dom'

import { reduxForm, Field } from 'redux-form'
import TextField from '@material-ui/core/TextField'

import DoneIcon from '@material-ui/icons/Done'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  button: {
    margin: theme.spacing.unit
  },
  card: {
    padding: 0,
    backgroundColor: '#F2F2F2',
  },
  pwchange: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  errorMessage: {
    color: theme.palette.secondary.main
  },
  doneCheck: {
    color: "#3eca00",
    fontSize: 48,
    margin: 24,
    marginTop: 16
  },
})

const validate = values => {
  const errors = {}
  const requiredFields = [
    'current',
    'new',
    'repeat'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Dit is een verplicht veld'
    }
  })
  if (values.new !== values.repeat) {
    errors.new = 'Wachtwoorden komen niet overeen'
  }
  if (values.new === values.current) {
    errors.new = 'Nieuw wachtwoord is gelijk aan huidig wachtwoord'
  }
  if (values.new !== undefined) {
    if (values.new.length < 10) {
      errors.uncompliant = 'Wachtwoord moet minstens uit 10 karakters bestaan'
    }
  }
  return errors
}

const renderTextField = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...custom
}) => (
    <TextField
      placeholder={label}
      label={label}
      error={touched && error}
      helperText={helperText}
      onChange={(event, value) => input.onChange(value)}
      {...input}
      {...custom}
    />
  )

function PwChangeCard (props)
{
  if (props.changed) {
    return <CardContent>
      <Typography variant="h5" component="h5" className={props.classes.pwchange}>
        Wachtwoord aanpassen
      </Typography>
      <div>
        <DoneIcon className={props.classes.doneCheck}/>
      </div>
      <Button variant="contained" color="primary" component={NavLink} to='/'>
        Naar Dashboard
      </Button>
    </CardContent>
  } else {
    return <CardContent>
      <Typography variant="h5" component="h5" className={props.classes.pwchange}>
        Wachtwoord aanpassen
      </Typography>
      <Typography variant="h6" component="h6" className={props.classes.pwchange} color="secondary">
        {props.error} {/* Hier komt de foutmelding te staan aangaande serverside-problemen */}
      </Typography>
      <form className={props.classes.container} noValidate autoComplete="off" onSubmit={props.handleSubmit}>
        <Field type='password' name='current' component={renderTextField} label='Huidig wachtwoord' margin='normal'
               autoFocus={true} ref={props.ref} className={props.classes.textField}/>
        <Field type='password' name='new' component={renderTextField} label='Nieuw wachtwoord' margin='normal'
               className={props.classes.textField}/>
        <Field type='password' name='repeat' component={renderTextField} label='Nieuw wachtwoord (nogmaals)' margin='normal'
               className={props.classes.textField}/>
        <p/>
        <Button type='submit' variant="contained" color="primary" className={props.classes.textField}>
          Indienen
        </Button>
      </form>
    </CardContent>
  }
}

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    const { classes } = props
    this.classes = classes

    this.state = { errorMessage: this.props.errorMessage }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
      this.props.confirmChanged();
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.pwchangeForm.syncErrors === undefined) {
      this.setState({errorMessage: undefined})
      this.props.tryChange(this.props.pwchangeForm.values.current, this.props.pwchangeForm.values.new)
    } else {
      this.setState({errorMessage: Object.values(this.props.pwchangeForm.syncErrors).join(",")})
    }
  }

  render() {
    let error = this.props.errorMessage

    if (this.state.errorMessage !== undefined) {
      error = this.state.errorMessage
    }

    return (
      <div className={this.classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Card className={this.classes.card}>
              <PwChangeCard classes={this.classes} changed={this.props.changed} error={error} handleSubmit={this.handleSubmit} handleConfirm={this.handleConfirm}/>
            </Card>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </div>
    )
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired
}

ChangePassword = reduxForm({
  form: 'pwchange',
  validate
})(ChangePassword)

export default withStyles(styles)(ChangePassword)
