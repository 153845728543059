import { connect } from "react-redux";
import Mfa from "../mfapage"
import { postMfa } from '../../actions/login'

const checkmfa = (state) => {
    return state.login.loggedIn
}

const generateMessage = (state) => {
    let error = state.login.mfa_error
    if (error !== undefined) {
        // the json failed to decode
        if (error === "Unexpected token P in JSON at position 0") {
            return "Onvoorzien probleem in de server" //Formatted string for display
        }
        // user error
        if (error === "mfa incorrect") {
            return "Verkeerde tweedefactor-sleutel" //Formatted string for display
        } else if (error === "username or password incorrect") {  
            // Mogelijk relevant als iemand de waardes van email en password tussendoor verandert
            return "Verkeerd e-mailadres of wachtwoord" //Formatted string for display
        }
        // Return the raw error
        return error
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        mfa_success: checkmfa(state),
        mfaForm: state.form.mfa,
        credentials: state.login.credentials,
        errorMessage: generateMessage(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        tryMfa: (email, password, verification_code) => {
            dispatch(postMfa({
                email: email,
                password: password,
                verification_code: verification_code
            }))
        }
    }
}

export { checkmfa, generateMessage, mapStateToProps, mapDispatchToProps }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mfa);