import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import Icon from '@material-ui/core/Icon'
import NwLogo from '../static/images/northwave/mini_logo.png'
import Link from 'react-router-dom/Link';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10
  },
  navButton: {
    marginLeft: 18,
    color: '#f2f2f2',
    backgroundColor: theme.palette.primary.main,
    '&:hover': { background: theme.palette.primary.light }
  },
  active: {
    color: '#f2f2f2',
    backgroundColor: theme.palette.primary.light,
    '&:hover': { background: theme.palette.primary.lighter }
  },
  grow: {
    flexGrow: 1
  },
  flex: {
    display: 'flex'
  },
  title: {
    marginLeft: 4
  },
  stealthLink: {
    color: '#f2f2f2',
    textDecoration: "none",
  }
})

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={this.props.className}
        >
          {this.props.username}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose} component={NavLink} to="/pwchange">Wachtwoord aanpassen</MenuItem>
        </Menu>
      </div>
    );
  }
}


class TopBar extends React.Component {

  render() {
    let user
    if (this.props.loggedIn) {
      user = (
        <div className={this.props.classes.flex}>
          <UserMenu username={this.props.loggedInAs} className={this.props.classes.navButton}/>
          <Button className={this.props.classes.navButton} onClick={this.props.logout}>
            Afmelden
          </Button>
        </div>
      )
    } else {
      user = (
        <div className={this.props.classes.flex}>
          <Button className={this.props.classes.navButton} component={NavLink} to='/login' activeClassName={this.props.classes.active}>
            Aanmelden
          </Button>
        </div>
      )
    }

    return (
      <div className={this.props.classes.root}>
        <AppBar position="static" color="primary">
          <Toolbar variant="dense">
            <Link to='/dashboard/security'>
              <Icon className={this.props.classes.menuButton} color="inherit" aria-label="Northwave">
                <img src={NwLogo} alt="Northwave Logo" />
              </Icon>
            </Link>
            <NavLink to='/dashboard/security' className={this.props.classes.stealthLink}>
              <Typography variant="h6" color="inherit" className={this.props.classes.title}>
                Northwave Dashboard
              </Typography>
            </NavLink>
            <div className={this.props.classes.grow} />
            {user}
          </Toolbar>
        </AppBar>
        <p />
      </div>
    )
  }
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TopBar)
