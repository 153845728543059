import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 18
  }
})

function ServiceDashboard (props) {
  const { classes } = props

  return (
    <div className={classes.root}>
      Service Dashboard filler
    </div>
  )
}

ServiceDashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServiceDashboard)