import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import ReactTable from "react-table";
import "react-table/react-table.css";

import { ResponsiveContainer, PieChart, Pie, BarChart, Bar, AreaChart, Area, CartesianGrid, XAxis, YAxis, Legend, Cell } from 'recharts';

import './rechart.css'
import './chartist.css'
import './dash.scss'

import moment from 'moment'

const northwaveBlue = "#00A8CA";
const colors = ['#668c51', '#42402c', '#bd524c', '#c0b593', '#0daea5', '#c2a94a', '#64c1bb', '#be9c67', '#c34941', '#70b2b7', '#c5b251', '#e98e3d'];

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  card: {
    padding: 0,
    backgroundColor: '#F2F2F2',
  },
  donutGraph: {
    float: 'right',
    marginBottom: 40,
  },
  dashTitle: {
    marginBottom: -10
  },
  labelStylingLine: {
    marginBottom: 30,
    '& svg': { overflow: "visible" },
    '& .ct-label.ct-horizontal': {
      justifyContent: "flex-end",
      transformOrigin: "100% 0",
      transform: "translate(-100%) rotate(-45deg)",
      width: "100px !important"
    }
  },
  labelStylingBar: {
    marginBottom: 30,
    '& svg': { overflow: "visible" },
    '& .ct-label.ct-horizontal': {
      justifyContent: "flex-end !important",
      textAlign: "right !important",
      transformOrigin: "95% 0",
      transform: "translate(-78%) rotate(-45deg)",
      width: "100px !important"
    },
    /* A custom rule for highlighting chart series-a elements */
    '& .ct-highlight': {
      fill: "#3eca00",
      stroke: "#3eca00",
    }
  },
  labelStylingPie: {
    padding: 15,
  },
  labelStylingIntel: {
    marginBottom: 30,
    '& svg': { overflow: "visible" },
    '& .ct-label.ct-horizontal': {
      justifyContent: "flex-end",
      transformOrigin: "100% 0",
      transform: "translate(-100%) rotate(-45deg)",
      width: "100px !important"
    }
  },
  dummyUseCase: {
    height: 162,
  },
  dummyUseCaseImg: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    height: 130,
    width: 130,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "block",
    position: "relative",
  },
  dummyUseCaseText: {
    position: "relative",
    top: "-43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize:  "40px",
    color: "#7A7A7A"
  },
  ucgStyling: {
    verticalAlign: "top",
  },
  ucgTitleStyling: {
    textAlign: "left",
  },
})

function currentMonth(skew = 0) {
  let d = new Date()
  let objDate = new Date(d.getFullYear(), (d.getMonth() + skew) % 12),
    locale = "nl-nl",
    month = objDate.toLocaleString(locale, { month: "long" });
  return month
}

function mapUseCase(afkorting) {
  const mapping = {
    "AO-PRI": "Privilege escalation",
    "EX-BRU":	"Brute force exploitation attempt",
    "CC-SNC": "Suspicious outbound network communication",
    "AO-CSC": "Cloud Service or Account compromise",
    "AO-RAT": "Usage of remote access tool",
    "AO-ACC":	"Account breached",
    "RE-BRU":	"Brute Force",
    "AO-EXF":	"Data Exfiltration",
    "AO-LAT":	"Lateral movement",
    "AO-DBC": "Database compromise attempt",
    "IN-MAL":	"Installation of Malware",
    "PV-APP":	"Illegal or aberrant application found on host",
    "PV-POL":	"Unauthorized modification of technical policy",
    "RE-FIP":	"Fingerprinting",
    "EX-NET":	"Network-based attack",
    "AO-FIL":	"File corruption, encryption and unauthorized access",
    "AO-EVA":	"Detection evasion techniques",
    "BL-IPB":	"IP blacklisting",
    "PV-VUL":	"Vulnerable system or service",
    "PV-ACC":	"Unauthorized use of high-privileged account",
    "NW-NDP":	"Northwave Detection Platform-related problems",
    "RE-POS":	"Port Scanning",
    "RE-PAS":	"Passive Reconnaissance",
    "DE-WEB":	"Web based malware Delivery",
    "DE-PHI":	"Delivery of phishing mail",
    "CC-CCH":	"Covert Channel",
    "AO-ALL":	"Multiple AO categories",
    "DD-APP":	"Application (D)DoS",
    "RE-SEN":	"Social Engineering",
    "EX-IDS":	"Network intrusion attempt",
    "EX-APP":	"Application intrusion attempt",
    "AO-CRE":	"Credential Theft",
    "FE-CEO":	"CEO Fraud",
    "PV-COM":	"Insecure communication protocol",
    "RE-MAP":	"Mail probing",
    "RE-SMH":	"Social Media Harvesting",
    "DE-EMA":	"Email based malware delivery",
    "DE-PHY":	"Physical malware delivery",
    "DE-WMO":	"Widespread malware outbreak",
    "DE-WRM":	"Worm propagation",
    "DE-RAT":	"Delivery of Remote Access Tool",
    "EX-AWE":	"Application whitelist evasion attempt",
    "IN-RAT":	"Installation of Remote Access Tool or similar backdoor",
    "AO-COL":	"Data Collection",
    "AO-REC":	"Internal Reconnaissance",
    "AO-BRU":	"Internal Brute Force",
    "AO-PER":	"Installation of persistence mechanism",
    "AO-RND":	"Rogue network device or service",
    "FE-EXT":	"Extortion attempts",
    "DD-VOL":	"Volume DDoS",
    "DD-PRO":	"Protocol (D)DoS",
    "PH-DCE":	"Illegal entry into datacenter",
    "PH-OFE":	"Illegal entry into office space",
    "PH-MOB":	"Mobile device lost or stolen",
    "PH-AAT":	"Anomalous access token usage",
    "PH-NAC":	"Physical network access compromise",
    "BL-MAB":	"Mail server blacklisting",
    "SD-CYS":	"Cyber Sabotage",
    "SD-PHS":	"Physical sabotage",
    "SD-DEF":	"Defacement",
    "PV-ICS":	"Illegal content streaming or usage of disallowed web service",
    "PV-PRC":	"Unauthorized process launched",
    "PV-RES":	"Resource abuse",
    "NL-UC1":	"Fingerprinting Attempts",
    "NL-UC2":	"Unauthorized use of privileged accounts",
    "NL-UC3":	"Remote Access Tool Usage",
    "NL-UC4":	"Office365 Compromise",
  }
  return mapping[afkorting]
}

class SecurityDashboard extends React.Component {
  constructor(props) {
    super(props);

    const { classes } = props
    this.classes = classes
  }

  multiChart(data) {
    if (data === undefined) {
      return <Typography>LOADING</Typography>
    } else {
      let series = data.series
      let labels = data.labels
      let series_names = Object.keys(series)
      let indices = [...Array(labels.length).keys()]

      let chart_data = indices.map(index => (Object.fromEntries(
            [['date', labels[index],]].concat(
                series_names.map(serie => ([serie, series[serie][index]]))
            ))))

      let chart_lines = [...Array(series_names.length).keys()].map(index => (
            <Area key={series_names[index]} name={series_names[index]} dataKey={series_names[index]} fill={colors[index]}stroke={colors[index]} isAnimationActive={false} stackId="1"/>
        ))

      return (<ResponsiveContainer width="100%" height="100%" minHeight={300}>
                    <AreaChart height={300} width={500} data={chart_data} margin={{bottom: 50}}>
                       <CartesianGrid strokeDasharray="3 3"/>
                       <XAxis interval={0} dataKey="date" angle="-90" textAnchor="end"/>
                       <YAxis/>
                       {chart_lines}
                       <Legend align="right" verticalAlign="middle" layout="vertical"/>
                   </AreaChart>
               </ResponsiveContainer>)
    }
  }

  getPieChartData() {
    let labels = []
    let series = []
    if (this.props.incident_use_cases !== undefined){
      for (const name in this.props.incident_use_cases) {
        if (this.props.incident_use_cases[name] !== 0) {
          labels.push(name)
          series.push(this.props.incident_use_cases[name])
        }
      }
    }
    return {
      labels: labels,
      series: series
    }
  }

  singleBarChart(data, ticks = 14) {
    let labels = Object.keys(data).slice(-ticks)

    let chart_data = labels.map(label => (Object.fromEntries(
      [['date', label],
       ['p', data[label]]]
      )))

    return <ResponsiveContainer width="100%" height="100%" minHeight={200}>
             <BarChart height={200} width={500} data={chart_data} margin={{ bottom: 50 }}>
               <CartesianGrid strokeDasharray="3 3"/>
               <XAxis interval={0} dataKey="date" angle={-45} textAnchor="end" />
               <YAxis/>
               <Bar dataKey="p" stroke={northwaveBlue} fill={northwaveBlue} fillOpacity={.1}/>
             </BarChart>
           </ResponsiveContainer>
  }
  singleAreaChart(data, ticks = 14) {
    let labels = Object.keys(data).slice(-ticks)

    let chart_data = labels.map(label => (Object.fromEntries(
      [['date', label],
       ['p', data[label]]]
      )))

    return <ResponsiveContainer width="100%" height="100%" minHeight={200}>
             <AreaChart height={200} width={500} data={chart_data} margin={{ bottom: 50 }}>
               <CartesianGrid strokeDasharray="3 3"/>
               <XAxis interval={0} dataKey="date" angle={-45} textAnchor="end" />
               <YAxis/>
               <Area dataKey="p" strokeWidth={3} stroke={northwaveBlue} fill={northwaveBlue} fillOpacity={.1} dot={true} isAnimationActive={false}/>
             </AreaChart>
           </ResponsiveContainer>
  }
  getUseCasePieChart() {
    let data = this.getPieChartData()

    let chart_data = data['labels'].map(label => (Object.fromEntries(
      [['name', label],
       ['p', this.props.incident_use_cases[label]]]
    )))

    function renderLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const label = chart_data[index].name;
      return (
        <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {label} {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
    function formatLegend(value, entry, index) {
      return value + " " + mapUseCase(value)
    }
    return <ResponsiveContainer width="100%" height="100%" minHeight={200}>
             <PieChart height={200} width={500} margin={{ bottom: 50 }}>
               <Pie data={chart_data} dataKey="p" isAnimationActive={false} fill={northwaveBlue} label={renderLabel} innerRadius="25%">
                 {chart_data.map((entry, index) => (
                   <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>
                 ))}
               </Pie>
               <Legend align="right" verticalAlign="middle" layout="vertical" formatter={formatLegend}/>
             </PieChart>
           </ResponsiveContainer>
  }
  yearChart(incident_year) {
    if (incident_year !== undefined) {
      return this.singleBarChart(incident_year, 12)
    } else {
      return <Typography>LOADING</Typography>
    }
  }

  monthChart(incident_month) {
    if (incident_month !== undefined) {
      return this.singleAreaChart(incident_month)
    } else {
      return <Typography>LOADING</Typography>
    }
  }

  intelChart(intel) {
    if (intel !== undefined) {
      return this.singleAreaChart(intel)
    } else {
      return <Typography>LOADING</Typography>
    }
  }

  getVerbalYear(nr) {
    if (this.props.incident_year_data !== undefined) {
      let year
      try { year = Number(Object.keys(this.props.incident_year_data)[nr].substr(0, 4)) } catch (e) { year = 0 }
      let thisYear = new Date().getFullYear()
      if (thisYear - year === 0) {
        return "dit jaar"
      } else if (thisYear - year === 1) {
        return "vorig jaar"
      } else {
        return String(year)
      }
    } else {
      return undefined
    }
  }

  getOpenTickets() {
    if (this.props.open_tickets !== undefined) {
      return this.props.open_tickets
    } else {
      return []
    }
  }

  componentDidMount() {
    if (this.props.open_tickets === undefined) {
      this.props.fetch_open_tickets()
    }
    if (this.props.incident_month_data === undefined) {
      this.props.fetch_incidents_month()
    }
    if (this.props.incident_year_data === undefined) {
      this.props.fetch_incidents_year()
    }
    if (this.props.incident_use_cases === undefined) {
      this.props.fetch_incident_use_cases()
    }
    if (this.props.incidents_use_case_scope_month === undefined) {
      this.props.fetch_incidents_use_case_scope_month()
    }
    if (this.props.incidents_use_case_business_risk_month === undefined) {
      this.props.fetch_incidents_use_case_business_risk_month()
    }
    if (this.props.intel === undefined) {
      this.props.fetch_intel()
    }
  }

  render() {
    return (
      <div className={this.classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Card className={this.classes.card}>

              <CardContent>
                <Typography variant="h4" className={this.classes.dashTitle}>
                  Intelligent Detection and Response Service Dashboard
          </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} />
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Aantal security-incidenten per dag
                </Typography>
                {this.monthChart(this.props.incident_month_data)}
                <Typography component="p">
                  Afgelopen 14 dagen
          </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Aantal security-incidenten per maand
                </Typography>
                {this.yearChart(this.props.incident_year_data)}
                <Typography component="p">
                  {currentMonth(1).replace(/^\w/, c => c.toUpperCase())} {this.getVerbalYear(0)}, tot {currentMonth()} {this.getVerbalYear(11)}.
          </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/*
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Aantal alarmen per scope per dag
                </Typography>
                {this.multiChart(this.props.incidents_use_case_scope_month)}
                <Typography component="p">
                  Afgelopen 14 dagen
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Aantal alarmen per business risk per dag
                </Typography>
                {this.multiChart(this.props.incidents_use_case_business_risk_month)}
                <Typography component="p">
                  {currentMonth(1).replace(/^\w/, c => c.toUpperCase())} {this.getVerbalYear(0)}, tot {currentMonth()} {this.getVerbalYear(11)}.
          </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        */}
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Security-incidenten per use-case
                </Typography>
                {this.getUseCasePieChart()}
                <Typography component="p">
                  Incidenten per use-case per 1 {currentMonth()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Aantal mogelijke cyberaanvallen
                </Typography>
                {this.intelChart(this.props.intel)}
                <Typography component="p">
                  Afgelopen 14 dagen
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Openstaande tickets
                </Typography>
                <ReactTable
                  data={this.getOpenTickets()}
                  columns={[
                      {
                        Header: "Northwave Ticketnummer",
                        accessor: "key",
                        maxWidth: 220,
                      },
                      {
                        Header: "Ticketnummer Klant",
                        accessor: "external-reference",
                        maxWidth: 180,
                      },
                      {
                        Header: "Risico",
                        accessor: "incident-risk",
                        sortMethod: (a, b) => {
                          if (a === b) {
                            return 0
                          }
                          const mapping = {
                            "High": 2,
                            "Medium": 1,
                            "Low": 0
                          }
                          a = mapping[a]
                          b = mapping[b]
                          return a < b ? -1 : 1;
                        },
                        maxWidth: 100,
                      },
                      {
                        Header: "Samenvatting",
                        accessor: "summary"
                      },
                      {
                        Header: "Datum",
                        id: "created",
                        accessor: d => {
                          let date = new Date(Date.parse(d.created))
                          return `${date.toLocaleTimeString('nl-NL', {})} ${date.toLocaleDateString('nl-NL', {})}`
                        },
                        sortMethod: (a, b) => {
                          /*
                          The sort method works on the output of the accessor, so
                          we have to parse the date again and sort
                          */
                          let format = "hh:mm:ss DD-MM-YYYY"
                          let m1 = moment(a, format)
                          let m2 = moment(b, format)
                          if (m1.isSame(m2)) {
                            return 0
                          }
                          return m1.isBefore(m2) ?  -1 : 1;
                        },
                        maxWidth: 200,
                      },
                      {
                        Header: "Oplosgroep",
                        accessor: "resolution-group",
                        maxWidth: 200,
                      },
                      {
                        Header: "Use-Case",
                        id: "use-case",
                        accessor: d => {
                          return mapUseCase(d['use-case'])
                        },
                        maxWidth: 200,
                      }
                    ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                  previousText='Vorige'
                  nextText='Volgende'
                  loadingText='Laden...'
                  noDataText='Geen rijen gevonden'
                  pageText='Pagina'
                  ofText='van'
                  rowsText='rijen'
                  defaultSorted={[
                    {
                      id: "incident-risk",
                      desc: true
                    },
                    {
                      id: "created",
                      desc: false
                    }
                  ]}
                />
                <br/>
                <Typography gutterBottom component="p">
                  Shift-klik voor multi-sort
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SecurityDashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SecurityDashboard)
