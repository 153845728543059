import React from "react";
import TextField from "@material-ui/core/TextField";

export const renderTextField = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    placeholder={label}
    label={label}
    error={touched && error}
    helperText={helperText}
    onChange={(event, value) => input.onChange(value)}
    {...input}
    {...custom}
  />
);
