
const default_state = {
    // By default, we are neither logged in nor logged out until we request the status
    // An error condition will reset this to undefined
    loggedIn: undefined,
    loggedInAs: "",
    status_loading: false,
    login_loading: false,
    logout_loading: false,
    mfa_loading: false,
    get_activation_loading: false,
    activate_loading: false,
    status_error: undefined,
    login_error: undefined,
    logout_error: undefined,
    mfa_error: undefined,
    get_activation_error: undefined,
    activate_error: undefined,
    status_response: undefined,
    login_response: undefined,
    logout_response: undefined,
    mfa_response: undefined,
    get_activation_response: undefined,
    activate_response: undefined,
}


const statusLogin = (state, email) => ({
    ...state,
    loggedIn: true,
    loggedInAs: email,
    status_loading: false,
    status_error: undefined,
    status_response: undefined
})

const statusError = (state, error) => ({
    ...state,
    loggedIn: undefined,
    loggedInAs: "",
    status_loading: false,
    status_error: error,
    status_response: undefined
})

const statusLogout = (state) => ({
    ...state,
    loggedIn: false,
    loggedInAs: "",
    status_loading: false,
    status_error: undefined,
    status_response: undefined
})

const loginSaveCredentials = (state, credentials) => ({
    ...state,
    // On a succesful login post, the credentials have been validated,
    // so we store them for use in the mfa post.
    credentials: credentials,
    login_response: undefined,
    login_error: undefined,
    login_loading: false
})

const loginError = (state, error) => ({
    ...state,
    credentials: undefined,
    login_response: undefined,
    login_error: error,
    login_loading: false
})

const logoutSuccess = (state) => ({
    ...state,
    logout_loading: false,
    logout_response: undefined,
    logout_error: undefined
})

const logoutError = (state, error) => ({
    ...state,
    logout_loading: false,
    logout_response: undefined,
    logout_error: error
})

const mfaRemoveCredentials = (state) => ({
    ...state,
    // Specifically set loggedIn to undefined, so we have to wait for the status request again
    loggedIn: undefined,
    credentials: undefined,
    mfa_error: undefined,
    mfa_response: undefined,
    mfa_loading: false
})

const mfaError = (state, error) => ({
    ...state,
    // We don't remove the credentials on error, we can use them again
    mfa_error: error,
    mfa_response: undefined,
    mfa_loading: false
})

const saveActivationData = (state, email, uri) => ({
    ...state,
    activation: {
        email: email,
        uri: uri
    },
    get_activation_loading: false,
    get_activation_error: undefined,
    get_activation_response: undefined
})

const getActivationError = (state, error) => ({
    ...state,
    get_activation_error: error,
    get_activation_response: undefined,
    get_activation_loading: false
})

const activateError = (state, error) => ({
    ...state,
    activate_error: error,
    activate_response: undefined,
    activate_loading: false
})

const activateSuccess = (state, email) => ({
    ...state,
    activation: {
        email: email,
        uri: undefined,
        success: true,
    },
    get_activation_loading: false,
    get_activation_error: undefined,
    get_activation_response: undefined
})

const login = (state = default_state, action) => {
    let result_state
    switch(action.type){
        // These are the saved fetch Response objects, which should not directly affect the state
        case "AUTH_STATUS_RESPONSE":
            return {...state, status_response: action.response}
        case "AUTH_LOGIN_RESPONSE":
            return {...state, login_response: action.response}
        case "AUTH_LOGOUT_RESPONSE":
            return {...state, logout_response: action.response}
        case "AUTH_MFA_RESPONSE":
            return {...state, mfa_response: action.response}
        case "AUTH_GET_ACTIVATION_RESPONSE":
            return {...state, get_activation_response: action.response}
        case "AUTH_ACTIVATE_RESPONSE":
            return {...state, activate_response: action.response}

        // These are the parsed JSON responses, which should affect the state
        case "AUTH_STATUS_JSON":
            if(state.status_response.status === 200 && action.json.status === "ok"){
                result_state = statusLogin(state, action.json.email)
            } else if(state.status_response.status === 401) {
                result_state = statusLogout(state)
            } else {
                result_state = statusError(state, "Onvoorziene fout")
            }
            return result_state
        case "AUTH_LOGIN_JSON":
            if (state.login_response.status === 200 && action.json.login === "mfa_required") {
                result_state = loginSaveCredentials(state, action.credentials)
            } else if(state.login_response.status === 401) {
                result_state = loginError(state, action.json.login)
            } else if(state.login_response.status === 429) {
                result_state = loginError(state, action.json.error)
            } else {
                result_state = loginError(state, "Onvoorziene aanmeldingsfout")
            }
            return result_state
        case "AUTH_LOGOUT_JSON":
            // We actually do not care if the logout succeeded server-side.
            // It is followed up by a new status request
            return logoutSuccess(state)
        case "AUTH_MFA_JSON":
            if(state.mfa_response.status === 200 && action.json.login === "ok") {
                result_state = mfaRemoveCredentials(state)
            } else if(state.mfa_response.status === 401 ) {
                result_state = mfaError(state, action.json.login)
            } else if(state.mfa_response.status === 429) {
                result_state = mfaError(state, action.json.error)
            } else {
                result_state = mfaError(state, "Onvoorziene meervoudige-verificatiefout")
            }
            return result_state
        case "AUTH_GET_ACTIVATION_JSON":
            if(state.get_activation_response.status === 200) {
                result_state = saveActivationData(state, action.json.email, action.json.uri)
            } else if(state.get_activation_response.status === 400) { // Key does not exist
                result_state = getActivationError(state, action.json.activate)
            }
            return result_state
        case "AUTH_ACTIVATE_JSON":
            if(state.activate_response.status === 200 && action.json.activate === "ok") {
                result_state = activateSuccess(state, action.json.email)
            } else if(state.activate_response.status === 400) {  // Key does not exist
                result_state = activateError(state, action.json.activate)
            } else if(state.activate_response.status === 401) {  // MFA incorrect
                result_state = activateError(state, action.json.activate)
            }
            return result_state

        // These are the errors encountered, which may reset the state
        case "AUTH_STATUS_ERROR":
            return statusError(state, action.error)
        case "AUTH_LOGIN_ERROR":
            return loginError(state, action.error)
        case "AUTH_LOGOUT_ERROR":
            return logoutError(state, action.error)
        case "AUTH_MFA_ERROR":
            return mfaError(state, action.error)
        case "AUTH_GET_ACTIVATION_ERROR":
            return getActivationError(state, action.error)
        case "AUTH_ACTIVATE_ERROR":
            return activateError(state, action.error)

        // These are the loading transitions
        case "AUTH_STATUS_LOADING":
            return {...state, status_loading: true}
        case "AUTH_LOGIN_LOADING":
            return {...state, login_loading: true}
        case "AUTH_LOGOUT_LOADING":
            return {...state, logout_loading: true}
        case "AUTH_MFA_LOADING":
            return {...state, mfa_loading: true}
        case "AUTH_GET_ACTIVATION_LOADING":
            return {...state, get_activation_loading: true}
        case "AUTH_ACTIVATE_LOADING":
            return {...state, activate_loading: true}

        // default
        default:
            return state
    }
}


export default login