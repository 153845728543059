import { connect } from "react-redux";
import Login from "../loginpage"
import { postLogin } from '../../actions/login'

const checkresult = (state) => {
    // Credentials will only be cached when they are valid,
    // so we can check this to see if the login attempt was
    // successful.
    return state.login.credentials !== undefined
}

const generateMessage = (state) => {
    let error = state.login.login_error
    if (error !== undefined) {
        // The json failed to decode
        if (error === "Unexpected token P in JSON at position 0") {
            return "Onvoorzien probleem in de server" //Formatted string for display
        }
        // User error
        if (error === "username or password incorrect") {
            return "Verkeerd e-mailadres of wachtwoord" //Formatted string for display
        }
        // Return the raw error
        return error
    }
}

const mapStateToProps = (state) => {
  return {
    loading: state.login.login_loading,
    success: checkresult(state),
    errorMessage: generateMessage(state),
    loginForm: state.form.login,
    loggedIn: state.login.loggedIn
  };
};

const mapDispatchToProps = dispatch => {
    return {
        tryLogin: (email, password) => {
            dispatch(postLogin({
                email: email,
                password: password
            }))
        }
    }
}

export { checkresult, generateMessage, mapStateToProps, mapDispatchToProps }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
