import { combineReducers } from 'redux'
import api from './api'
import login from './login'
import pwchange from './pwchange'
import { reducer as reduxFormReducer } from 'redux-form'

export default combineReducers({
  api,
  login,
  pwchange,
  form: reduxFormReducer
})
