import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

// Redux
import { createStore, applyMiddleware } from 'redux'
// rootReducer
import rootReducer from './reducers/index'

const store = createStore(rootReducer, applyMiddleware(
  createLogger(),
  thunkMiddleware
))

export default store