// This fixes "Object doesn't support property or method 'includes'"
// and probably a lot more errors in MS Edge
import 'core-js'
// This fixes 'fetch' is undefined in MS Edge
import 'isomorphic-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { Provider, connect } from "react-redux"
import store from './redux'

import SecurityDashboard from './dashboard/securitydashboardcontainer.js'
import ServiceDashboard from './dashboard/servicedashboard.js'
import TopBar from './main/topbarcontainer.js'
import SubBar from './main/subbar.js'
import Login from './login/containers/login.js'
import Mfa from './login/containers/mfa.js'
import Activation from './login/containers/activation'
import ChangePassword from './login/containers/pwchange.js'
import SecurityReports from './reports/securityreports.js'
import ServiceReports from './reports/servicereports.js'
import { getStatus } from './actions/login'

import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: { // Donkergrijs
      lighter: '#5C6E79',
      light: '#41535E',
      main: '#28343C',
      dark: '#192B36',
      darker: '#0D1F2B',
      contrastText: '#F2F2F2'
    },
    secondary: { // Roestrood
      lighter: '#FFB297',
      light: '#D67A5B',
      main: '#B75433',
      dark: '#923617',
      darker: '#671A00',
      contrastText: '#000'
    },
    tertiary: { // Wit-af
      main: '#F2F2F2',
      contrastText: '#000'
    }
  }
})

// Donkergrijs #28343C
// Blauwgrijs #405A67
// Wit-af #F2F2F2
// Helderblauw #00A8CA
// Roestrood #B75433

const secdash = () => (
  <div>
    <SubBar path='/dashboard' />
    <SecurityDashboard />
  </div>
)

const serdash = () => (
  <div>
    <SubBar path='/dashboard' />
    <ServiceDashboard />
  </div>
)

const login = () => (
  <Login />
)

const mfa = () => (
  <Mfa />
)

const pwchange = () => (
  <ChangePassword />
)

const secreports = () => (
  <div>
    <SubBar path='/reports' />
    <SecurityReports />
  </div>
)

const serreports = () => (
  <div>
    <SubBar path='/reports' />
    <ServiceReports />
  </div>
)

const main = () => (
  <div>
    <Redirect to={'/dashboard/security'} />
  </div>
)

let PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
    <Route {...rest} render={props => {
      if (loggedIn === undefined) {
        // The authentication status is still loading
        return <div></div>
      } else if (loggedIn) {
        return <Component {...props} />
      } else {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    }} />
)

PrivateRoute = connect((state) => ({
  loggedIn: state.login.loggedIn
}))(PrivateRoute)

// The App component wraps the whole application and is in
// charge of state that matters for the whole application
class App extends React.Component {
  componentDidMount(){
    // At first load, the login status will be undefined
    // This variable determines if the status request will
    // be executed.
    if(this.props.should_call_status) {
      this.props.getStatus()
    }
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

App = connect(
  (state) => ({ should_call_status: state.login.loggedIn === undefined }),
  (dispatch) => ({ getStatus: () => dispatch(getStatus()) })
)(App)


ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App>
        <HashRouter>
          <div>
            {/* prefLink is om in te stellen wat de default pagina is waar een gebruiker op landt. Mogelijke waarden: "/security" of "/service" */}
            <TopBar prefLink='/security' />
            <Switch>
              <Route exact path='/' component={main} />
              <Route exact path='/login' component={login} />
              <Route exact path='/login/mfa' component={mfa} />
              <PrivateRoute exact path='/pwchange' component={pwchange} />
              <Route path='/login/activation/:key' component={Activation} />

              <PrivateRoute exact path='/dashboard/security' component={secdash} />
              <PrivateRoute exact path='/dashboard/service' component={serdash} />

              <PrivateRoute exact path='/reports/security' component={secreports} />
              <PrivateRoute exact path='/reports/service' component={serreports} />
            </Switch>
          </div>
        </HashRouter>
      </App>
    </MuiThemeProvider>
  </Provider>
  , document.getElementById('root')
)
