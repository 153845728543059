import TopBar from './topbar'
import { connect } from 'react-redux'
import { postLogout } from '../actions/login'

const mapStateToProps = (state) => ({
    loggedIn: state.login.loggedIn,
    loggedInAs: state.login.loggedInAs
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(postLogout())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)