const actions = ["FETCH_BEGIN", "FETCH_SUCCESS", "FETCH_ERROR", "RESET_API"];
const initFetchState = {
  loading: false,
  result: undefined,
  error: undefined
};
const initApiState = {
  open_tickets: {...initFetchState},
  incidents_month: {...initFetchState},
  incidents_year: {...initFetchState},
  incidents_use_cases: {...initFetchState},
  incidents_use_case_scope_month: {...initFetchState},
  incidents_use_case_business_risk_month: {...initFetchState},
  intel: {...initFetchState},
};

const api = (state = initApiState, action) => {
  if (actions.includes(action.type)) {
    let result = { ...state };
    result[action.name] = apiReducer(state[action.name], action);
    return result;
  }
  return state;
};

const apiReducer = (state = initFetchState, action) => {
  switch (action.type) {
    case "FETCH_BEGIN":
      return {
        loading: true,
        error: undefined,
        result: undefined
      };

    case "FETCH_SUCCESS":
      return {
        loading: false,
        error: undefined,
        result: action.json
      };

    case "FETCH_ERROR":
      return {
        loading: false,
        error: action.error,
        result: undefined
      };
    case "RESET_API":
      return initFetchState
    default:
      return state;
  }
};

export default api;
