const API_PWCHANGE = '/api/auth/pwchange'

const pwchangeLoading = () => ({
  type: "PWCHANGE_LOADING"
})

const pwchangeResponse = (response) => ({
  type: "PWCHANGE_RESPONSE",
  response: response
})

const changeJson = (json) => ({
  type: "PWCHANGE_JSON",
  json: json
})

export const confirm = () => ({
  type: "PWCHANGE_CONFIRM"
})

export const complete = () => ({
  type: "PWCHANGE_COMPLETE"
})

const pwchangeError = (error) => ({
  type: "PWCHANGE_ERROR",
  error: error
})

export const postChange = (credentials) => (
  (dispatch) => {
    dispatch(pwchangeLoading())

    let options = {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json"
      }
    }
    fetch(API_PWCHANGE, options).then(
      response => {
        dispatch(pwchangeResponse(response))
        return response.json()
      }
    ).then(
      json => dispatch(changeJson(json)),
    ).catch (
      error => dispatch(pwchangeError(error))
    )
  }
)
