import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { Redirect } from 'react-router-dom'

import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../helpers/forms'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  button: {
    margin: theme.spacing.unit
  },
  card: {
    padding: 0,
    backgroundColor: '#F2F2F2',
  },
  login: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  errorMessage: {
    color: theme.palette.secondary.main
  }
})

const validate = values => {
  const errors = {}
  const requiredFields = [
    'email',
    'password'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true //'required' (TODO helperText)
    }
  })
  if (
    values.email &&
    !/[^@]*[@][^@]*/i.test(values.email)
  ) {
    errors.email = true //'Invalid email address'
  }
  return errors
}


class Login extends React.Component {
  constructor(props) {
    super(props);

    const { classes } = props
    this.classes = classes

    this.state = { errorMessage: this.props.errorMessage }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    // syncErrors moeten de request voorkomen; this.props.loginForm.syncErrors.email bv
    if (this.props.loginForm.syncErrors === undefined) {
      this.setState({errorMessage: undefined})
      this.props.tryLogin(this.props.loginForm.values.email, this.props.loginForm.values.password)
    } else {
      this.setState({errorMessage: "Verkeerd ingevoerd e-mailadres of wachtwoord"})
    }
  }


  render() {
    if (this.props.loggedIn) {
      return <Redirect to='/dashboard/security' />
    }
    if (this.props.success) {
      return <Redirect to='/login/mfa' />
    }
    let error = this.props.errorMessage
    
    if (this.state.errorMessage !== undefined) {
      error = this.state.errorMessage
    }

    return (
      <div className={this.classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Card className={this.classes.card}>
              <CardContent>
                <Typography variant="h5" component="h5" className={this.classes.login}>
                  Aanmelden
                </Typography>
                <Typography variant="h6" component="h6" className={this.classes.login} color="secondary">
                  {error} {/* Hier komt de foutmelding te staan aangaande serverside-problemen */}
                </Typography>
                <form className={this.classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                  <Field name='email' component={renderTextField} label='e-mailadres' autoFocus={true} margin='normal' className={this.classes.textField} />
                  <Field type='password' name='password' component={renderTextField} label='wachtwoord' margin='normal' className={this.classes.textField} />
                  <p />
                  <Button type='submit' variant="contained" color="primary" className={this.classes.button} >
                    Verder
                </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </div>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
}

Login = reduxForm({
  form: 'login',
  validate
})(Login)

export default withStyles(styles)(Login)
