
const default_state = {
    changed: false,
    loading: false,
    error: undefined,
    response: undefined,
    confirmed: false
}

const statusChange = (state) => ({
    ...state,
    changed: true,
    confirmed: false,
    loading: false,
    error: undefined,
    response: undefined
})

const statusError = (state, error) => ({
    ...state,
    loading: false,
    error: error.message,
    response: undefined
})

const pwchange = (state = default_state, action) => {
    let result_state
    switch(action.type){
        case "PWCHANGE_RESPONSE":
            return {...state, response: action.response}
        case "PWCHANGE_CONFIRM":
            return {...state, ...default_state }
        case "PWCHANGE_COMPLETE":
            return {...state, ...default_state }
        // These are the parsed JSON responses, which should affect the state
        case "PWCHANGE_JSON":
            if(state.response.status === 200 && action.json.pwchange === "ok"){
                result_state = statusChange(state)
            } else {
                result_state = statusError(state, "Onvoorziene fout")
            }
            return result_state

        // These are the errors encountered, which may reset the state
        case "PWCHANGE_ERROR":
            return statusError(state, action.error)

        // These are the loading transitions
        case "PWCHANGE_LOADING":
            return {...state, loading: true}

        // default
        default:
            return state
    }
}

export default pwchange