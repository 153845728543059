import React from "react";
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm, Field } from 'redux-form'
import { renderTextField } from '../helpers/forms'
import { CardActions } from "@material-ui/core";
import { Redirect, NavLink } from 'react-router-dom'

import QR from 'qrcode.react'

import DoneIcon from '@material-ui/icons/Done'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    padding: 0,
    backgroundColor: "#F2F2F2"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  errorMessage: {
    color: theme.palette.secondary.main
  },
  activation: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: theme.spacing.unit
  },
  qrCode: {
    marginTop: 16,
    marginBottom: 16,
  },
  doneCheck: {
    color: "#3eca00",
    fontSize: 48,
    margin: 24,
    marginTop: 16
  },
});

const validate = values => {
  const errors = {}
  const requiredFields = [
    'newpassword',
    'newpasswordagain',
    'mfa'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true //'required' (TODO helperText)
    }
  })
  if (values.newpassword !== values.newpasswordagain) {
    errors.newpassword = true //'Niet dezelfde passwords'
    errors.newpasswordagain = true
  }
  if ((values.newpassword !== undefined) && (values.newpasswordagain !== undefined)) {
    if ((values.newpassword.length < 10) || (values.newpasswordagain.length < 10)) {
      errors.uncompliant = true //'Password niet 10 chars of langer'
    }
  }
  if (values.mfa && !/^[0-9]{6}$/i.test(values.mfa)) {
    errors.mfa = true //'Invalid email address'
  }
  return errors
}

class activation extends React.Component {
  constructor(props) {
    super(props);

    const { classes } = props
    this.classes = classes

    this.state = {
      errorMessage: this.props.errorMessage,
      success: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.activationForm.syncErrors === undefined) {
      this.setState({ errorMessage: undefined })
      this.props.tryActivate(this.props.match.params.key, this.props.activationForm.values.newpassword, this.props.activationForm.values.mfa)
    } else if (this.props.activationForm.syncErrors.newpassword) {
      this.setState({ errorMessage: "Wachtwoorden komen niet overeen" })
      this.setState({ errorMessage: "Wachtwoord moet uit minstens 10 karakters bestaan" })
    } else if (this.props.activationForm.syncErrors.mfa) {
      this.setState({ errorMessage: "Tweedefactor-sleutel is niet geldig" })
    } else {
      this.setState({ errorMessage: "Onbekend probleem" })
    }
  }

  componentDidMount() {
    if (this.props.activation === undefined) {
      this.props.get_activation(this.props.match.params.key)
    }
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to='/dashboard/security' />
    }

    if (this.state.success) {
      return <Redirect to='/login' />
    }
    let error = this.state.errorMessage

    if (this.state.errorMessage !== undefined) {
      error = this.state.errorMessage
    }

    if (this.props.activation === undefined) {
      return <Typography variant="h6" component="h6" className={this.classes.login} color="secondary">
          Ongeldige activatiekoppeling
        </Typography>
    }

    let qrcode = (
      <div>
        <p/>
        QR-code
        <p/>
      </div>
    )
    if (this.props.activation !== undefined && this.props.activation.uri !== undefined) {
      qrcode = (
        <Grid container justify = "center">
        <QR value={this.props.activation.uri} className={this.props.classes.qrCode} />
        </Grid>
      )
    }

    let cardContent = (
      <div>
        <Typography variant="h6" component="h6" className={this.classes.login} color="secondary">
          {error} {/* Hier komt de foutmelding te staan aangaande serverside-problemen */}
        </Typography>
        <form className={this.props.classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <Field type='password' name='newpassword' component={renderTextField} label='Password' margin='normal' className={this.props.classes.textField} />
          <Field type='password' name='newpasswordagain' component={renderTextField} label='Confirm Password' margin='normal' className={this.props.classes.textField} />

          <Typography variant="body1" className={this.classes.login} color="primary">
            Graag de volgende QR-code inlezen op uw verificatie-applicatie
                  </Typography>
          {qrcode}
          <Typography variant="body1" className={this.classes.login} color="primary">

          </Typography>

          <Field name='mfa' component={renderTextField} label='Tweedefactor-sleutel' margin='normal' className={this.classes.textField} />
          <CardActions>
            <Button type="submit" variant="contained" color="primary" className={this.props.classes.button} >
              Activeer
            </Button>
          </CardActions>
        </form>
      </div>
    )
    if (this.props.activation !== undefined && this.props.activation.success) {
      cardContent = (
        <div>
          <div>
          <DoneIcon className={this.props.classes.doneCheck}/>
          </div>
          <Typography variant="body1" className={this.props.classes.activation}>
            Graag op de knop hieronder klikken en uzelf aanmelden met uw e-mail adres ({this.props.activation.email}).
          </Typography>
          <Button variant="contained" color="primary" component={NavLink} to='/login'>
            Aanmelden
          </Button>
        </div>
      )
    }

    return (
      <div className={this.props.classes.root}>
        <Grid
          container
          spacing={16}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Card className={this.props.classes.card}>
              <CardContent>
                <Typography variant="h5" component="h5" className={this.props.classes.activation}>
                  {this.props.activation ? this.props.activation.email : 'Onbekende'} aanmelden
                </Typography>
                {cardContent}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </div>
    );
  }
}

activation.propTypes = {
  classes: PropTypes.object.isRequired
}

activation = reduxForm({
  form: 'activation',
  validate
})(activation)

export default withStyles(styles)(activation);
